<template>
    <div>
        <label :class="['label-text', fieldData.hasError ? 'error' : '']">
            {{ fieldData.label }}
        </label>
        <date-picker
            v-model="fieldData.tempEndDate"
            :months="months"
            :weekdays="weekdays"
            :class="['placedatepickercrossinmiddle', fieldData.hasError ? 'errorinput' : '', fieldData.editable === false ? 'disableddateto' : '', 'datepicker']"
            :editable="fieldData.editable"
            :show-week-number="true"
            valueType="format"
            :disabled-date="isDateDisabled"
            :clearable="false"
        ></date-picker>
        <label class="text-danger" v-if="fieldData.errorMsg.length > 0">{{ fieldData.errorMsg }}</label>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import "vue2-datepicker/locale/sv"
import computedVal from "@/mixins/computedVal"
import props from "@/mixins/inputProps"
import propsDate from "@/mixins/inputPropsDate"
import datePickerConfig from "@/mixins/datePickerConfig"
import { isDateNotBetweenTwoDates } from "../../../../api/utils.js"
import dayjs from "dayjs"
export default {
    name: "DateToPrograms",
    components: {
        DatePicker,
    },
    mixins: [computedVal, props, propsDate, datePickerConfig],

    computed: {
        isDateValidFormat() {
            return dayjs(this.fieldData.tempEndDate, "YYYY-MM-DD").format("YYYY-MM-DD") === this.fieldData.tempEndDate
        },
    },
    mounted() {
        if (this.fieldData.tempEndDate) this.checkIfError(this.fieldData.tempEndDate)
    },
    methods: {
        isDateDisabled(datepickerDate) {
            let startDate = new Date(this.$store.getters.getPublishedFromDate)
            let endDate = new Date(this.latestDate)
            let disableDate = isDateNotBetweenTwoDates(datepickerDate, startDate, endDate)
            return disableDate
        },
        checkIfError(chosendate) {
            this.fieldData.hasError = false
            this.fieldData.errorMsg = ""
            if ((this.isDateDisabled(new Date(chosendate)) && this.fieldData.required) || !this.isDateValidFormat) {
                this.fieldData.hasError = true
                this.fieldData.errorMsg = "Det saknas tittardata för valt datum eller så är datumet i fel format"
            }
        },
    },
    watch: {
        "fieldData.tempEndDate"(chosendate) {
            this.checkIfError(chosendate)
        },
    },
}
</script>

<style scoped>
input {
    height: 30px !important;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    padding: 2px 5px !important;
}
.disableddateto >>> input {
    background-color: var(--vs-disabled-bg);
    cursor: var(--vs-disabled-cursor);
}
</style>
