<template>
    <div>
        <div v-if="showPublishDatePickers">
            <b-row class="px-2 mt-3">
                <b-col lg="6">
                    <label class="label-text">
                        Publiceringsdatum från
                    </label>
                    <date-picker
                        v-model="fieldData.tempPubStartDate"
                        :months="months"
                        :weekdays="weekdays"
                        :class="['placedatepickercrossinmiddle', 'datepicker']"
                        :show-week-number="true"
                        valueType="format"
                        :disabled-date="isDateDisabled"
                        :clearable="false"
                    >
                    </date-picker>
                </b-col>
                <b-col lg="6" class="pl-0">
                    <label class="label-text">
                        Publiceringsdatum till
                    </label>
                    <div class="wrapsmallbtns">
                        <b-button size="sm" @click="hidePubDate" v-b-tooltip.hover title="använd ej" variant="primary" class="pubdatetobtn">använd ej</b-button>
                    </div>
                    <date-picker
                        v-model="fieldData.tempPubEndDate"
                        :months="months"
                        class="datepicker"
                        :weekdays="weekdays"
                        :show-week-number="true"
                        valueType="format"
                        :disabled-date="isDateDisabled"
                        :clearable="false"
                    >
                    </date-picker>
                </b-col>
            </b-row>
        </div>
        <b-button v-else @click="clickedPublishDate" size="sm" class="usepubdatebtn btnclass mt-3 ml-2">
            Använd pubdatum
        </b-button>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import "vue2-datepicker/locale/sv"
import props from "@/mixins/inputProps"
import propsDate from "@/mixins/inputPropsDate"
import datePickerConfig from "@/mixins/datePickerConfig"
import * as Mutations from "@/store/modules/programs/mutation-types"
import globalConfig from "@/mixins/globalConfig"
import dayjs from "dayjs"

export default {
    name: "PublishDate",
    components: {
        DatePicker,
    },
    watch: {
        async "fieldData.tempPubStartDate"() {
            let startDate = this.fieldData.tempPubStartDate
            let endDate = this.fieldData.tempPubEndDate

            if (!this.isValidDate(endDate) || !this.isValidDate(startDate)) {
                return
            }
            if (startDate > endDate) {
                this.fieldData.tempPubEndDate = startDate
            }
        },
        async "fieldData.tempPubEndDate"() {
            let startDate = this.fieldData.tempPubStartDate
            let endDate = this.fieldData.tempPubEndDate

            if (!this.isValidDate(endDate) || !this.isValidDate(startDate)) {
                return
            }
            if (startDate > endDate && endDate) {
                this.fieldData.tempPubStartDate = endDate
            }
        },
    },
    mixins: [props, propsDate, globalConfig, datePickerConfig],
    async mounted() {
        await this.loadGlobalConfigFromFile()
    },

    computed: {
        showPublishDatePickers() {
            return this.fieldData.tempSearchForPubDate
        },
        pubDateFrom: {
            get() {
                if (this.$store.getters[this.getter].pub_start_date > this.$store.getters[this.getter].start_date) {
                    this.$store.commit(Mutations.setProgramsPubStartdate, this.$store.getters[this.getter].start_date)
                }
                return this.$store.getters[this.getter]?.pub_start_date ? this.$store.getters[this.getter].pub_start_date : ""
            },
            set(val) {
                let updatedQuery
                if (this.config_ShowConsumtionPeriodProgram && val > this.$store.getters[this.getter].end_date) {
                    val = this.$store.getters[this.getter].end_date
                } else if (!this.config_ShowConsumtionPeriodProgram && val > this.$store.getters[this.getter].start_date) {
                    val = this.$store.getters[this.getter].start_date
                } else if (val > this.$store.getters[this.getter].pub_end_date) {
                    if (val > this.$store.getters[this.getter].start_date) {
                        val = this.$store.getters[this.getter].start_date
                    }
                    updatedQuery = {
                        ...this.$store.getters[this.getter],
                        pub_end_date: val,
                        pub_start_date: val,
                    }
                    return this.$store.commit(this.setter, updatedQuery)
                }
                this.$store.commit(Mutations.setProgramsPubStartdate, val)
            },
        },
        pubDateTo: {
            get() {
                if (!this.config_ShowConsumtionPeriodProgram && this.$store.getters[this.getter].pub_end_date > this.$store.getters[this.getter].start_date) {
                    this.$store.commit(Mutations.setProgramsPubEnddate, this.$store.getters[this.getter].start_date)
                } else if (this.$store.getters[this.getter].pub_end_date > this.$store.getters[this.getter].end_date) {
                    this.$store.commit(Mutations.setProgramsPubEnddate, this.$store.getters[this.getter].end_date)
                }
                return this.$store.getters[this.getter]?.pub_end_date ? this.$store.getters[this.getter].pub_end_date : ""
            },
            set(val) {
                let updatedQuery
                if (val > this.$store.getters[this.getter].end_date) {
                    val = this.$store.getters[this.getter].end_date
                } else if (val < this.$store.getters[this.getter].pub_start_date) {
                    updatedQuery = {
                        ...this.$store.getters[this.getter],
                        pub_end_date: val,
                        pub_start_date: val,
                    }
                    return this.$store.commit(this.setter, updatedQuery)
                }
                this.$store.commit(Mutations.setProgramsPubEnddate, val)
            },
        },
    },
    methods: {
        isValidDate(date) {
            return dayjs(date, "YYYY-MM-DD").format("YYYY-MM-DD") === date
        },
        clickedPublishDate() {
            this.fieldData.tempSearchForPubDate = true
            this.fieldData.tempPubStartDate = this.$store.getters.getProgramsQuery.start_date
            this.fieldData.tempPubEndDate = this.$store.getters.getProgramsQuery.end_date
        },
        hidePubDate() {
            this.fieldData.tempSearchForPubDate = false
        },
        isDateDisabled(datepickerDate) {
            let endDate = new Date(this.latestDate)
            let disableDate = false
            if (datepickerDate > endDate) disableDate = true
            return disableDate
        },
    },
}
</script>
<style scoped>
input {
    height: 30px !important;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    padding: 2px 5px !important;
}

.pubDatePicker {
    width: 50%;
    float: right;
}
.pubdatetobtn {
    font-size: 0.5625em;
    padding-right: 0.188rem;
    padding-left: 0.188rem;
    padding-top: 0.2em;
    margin-left: 0.188rem;
    float: right;
}
>>> .usepubdatebtn {
    color: black;
    font-size: 0.7rem;
}
</style>
