<template>
    <div class="mr-3">
        <b-tooltip v-if="fieldData.tooltip_text" :target="fieldData.identifier" triggers="hover">
            {{ fieldData.tooltip_text }}
        </b-tooltip>

        <button type="button" :class="['btn btnclass smallerfontsize', showEmptyAnswers ? 'selected' : '']" @click="clickedBtn">
            {{ showEmptyAnswers ? "Visar tomma svar" : "Döljer tomma svar" }}
        </button>
    </div>
</template>

<script>
import computedVal from "@/mixins/computedVal"
import props from "@/mixins/inputProps"
export default {
    name: "ToggleButton",
    mixins: [computedVal, props],
    computed: {
        showEmptyAnswers() {
            return this.$store.getters[this.getter][this.fieldData.identifier]
        },
    },
    methods: {
        clickedBtn() {
            const new_state = { ...this.$store.getters[this.getter], [this.fieldData.identifier]: !this.showEmptyAnswers }
            this.$store.commit(this.setter, new_state)
        },
    },
}
</script>

<style scoped>
.btnclass {
    width: 11rem;
}
</style>
