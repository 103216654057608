<template>
    <div>
        <b-dropdown toggle-class="columndd" class="w-100 column" variant="outline-secondary" :text="fieldData.label">
            <b-form-group>
                <b-form-checkbox v-model="allIsSelected" @change="selectAll" class="mx-2">
                    {{ allIsSelected ? "Avmarkera alla" : "Välj Alla" }}
                </b-form-checkbox>
                <b-form-checkbox-group v-model="value" :options="fieldData.config.options" class="mx-2" @click.native="allIsSelected = false" stacked></b-form-checkbox-group>
            </b-form-group>
        </b-dropdown>
    </div>
</template>

<script>
import computedVal from "@/mixins/computedVal"
import props from "@/mixins/inputProps"

export default {
    name: "CheckboxDropdownColumns",
    mixins: [computedVal, props],
    data() {
        return {
            allIsSelected: true,
        }
    },
    methods: {
        getSelectedColumnsCount() {
            let selected = this.$store.getters[this.getter]?.[this.fieldData.identifier] ? this.$store.getters[this.getter][this.fieldData.identifier] : []
            return selected.length
        },

        selectAll(checked) {
            let updatedQuery
            if (checked) {
                updatedQuery = {
                    ...this.$store.getters[this.getter],
                    [this.fieldData.identifier]: this.fieldData.config.options.map(opt => opt.value),
                }
            } else {
                let options = this.fieldData.config.options.filter(opt => {
                    if (opt.disabled) return opt
                })
                updatedQuery = {
                    ...this.$store.getters[this.getter],
                    [this.fieldData.identifier]: options.map(opt => opt.value),
                }
            }
            return this.$store.commit(this.setter, updatedQuery)
        },
    },
}
</script>
<style scoped>
>>> .custom-control.custom-checkbox{
    display: flex;
}
</style>