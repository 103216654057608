<template>
    <div>
        <date-picker
            v-model="fieldData.tempStartDate"
            :months="months"
            :weekdays="weekdays"
            :class="[fieldData.hasError ? 'errorinput' : '', 'datepicker']"
            :show-week-number="true"
            valueType="format"
            :disabled-date="isDateDisabled"
            :clearable="false"
            ref="datepickerStartdate"
        ></date-picker>
        <label class="text-danger text-center" v-if="fieldData.errorMsg.length > 0">{{ fieldData.errorMsg }}</label>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import "vue2-datepicker/locale/sv"
import props from "@/mixins/inputProps"
import propsDate from "@/mixins/inputPropsDate"
import datePickerConfig from "@/mixins/datePickerConfig"
import { isDateNotBetweenTwoDates } from "../../../../api/utils.js"
import dayjs from "dayjs"
import globalConfig from "../../../../mixins/globalConfig"
export default {
    name: "DateFromPrograms",
    components: {
        DatePicker,
    },
    mixins: [props, propsDate, globalConfig, datePickerConfig],
    computed: {
        isDateValidFormat() {
            return dayjs(this.fieldData.tempStartDate, "YYYY-MM-DD").format("YYYY-MM-DD") === this.fieldData.tempStartDate
        },
    },
    async mounted() {
        await this.loadGlobalConfigFromFile()
        require("dayjs/locale/sv")
        dayjs.locale("sv")
        if (this.fieldData.tempStartDate) this.checkIfError(this.fieldData.tempStartDate)
        this.$nextTick(() => {
            if (!this.config_ShowConsumtionPeriodProgram) {
                this.fieldData.label = "Konsumtionsdag"
            } else {
                this.fieldData.label = "Konsumtionsperiod från"
            }
        })
    },
    methods: {
        isDateDisabled(datepickerDate) {
            let startDate = new Date(this.$store.getters.getPublishedFromDate)
            let endDate = new Date(this.latestDate)
            let disableDate = isDateNotBetweenTwoDates(datepickerDate, startDate, endDate)
            return disableDate
        },
        checkIfError(chosendate) {
            this.fieldData.hasError = false
            this.fieldData.errorMsg = ""
            if ((this.isDateDisabled(new Date(chosendate)) && this.fieldData.required) || !this.isDateValidFormat) {
                this.fieldData.hasError = true
                this.fieldData.errorMsg = "Det saknas tittardata för valt datum eller så är datumet i fel format"
            }
        },
    },
    watch: {
        "fieldData.tempStartDate"(chosendate) {
            this.checkIfError(chosendate)
        },
    },
}
</script>
<style scoped>
input {
    height: 30px !important;
    box-sizing: border-box;
    border: 1px solid #ced4da;
    padding: 2px 5px !important;
}
</style>
